export function setPasswordFlag(value) {
    return dispatch => {dispatch(setChangePasswordFlag(value))}
}

export function setProfileData(profile) {
    return dispatch => dispatch(setProfile(profile))
}
export function setRoles(roles) {
    return dispatch => dispatch(setUserRoles(roles))
}
export function setUserResponse(user) {
    return dispatch => dispatch(setUserdetail(user))
}
export function setWhiteLabeling(response) {
    return dispatch => dispatch(setWhiteLabelingResponse(response))
}

export function setPasswordAPIFlag(value) {
    return dispatch => {dispatch(setChangePasswordAPIFlag(value))}
}

export const FETCH_PRODUCTS_BEGIN = "FETCH_PRODUCTS_BEGIN";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";
export const SET_CHANGE_PASSWORD_FLAG = "SET_CHANGE_PASSWORD_FLAG";
export const SET_CHANGE_PASSWORD_API_FLAG = "SET_CHANGE_PASSWORD_API_FLAG";
export const SET_PROFILE = "SET_PROFILE";
export const SET_USERDETAIL = "SET_USERDETAIL";
export const SET_USER_ROLES = "SET_USER_ROLES";
export const SET_WHITELABELING_RESPONE = "SET_WHITELABELING_RESPONE";
export const SET_BRAND_COLOR = "SET_BRAND_COLOR";
export const SET_BRAND_DARK_COLOR = "SET_BRAND_DARK_COLOR";


export const setUserdetail = object => ({
    type: SET_USERDETAIL,
    payload: {object}
});

export const setProfile = profile => ({
    type: SET_PROFILE,
    payload: { profile }
});

export const fetchProductsBegin = () => ({
    type: FETCH_PRODUCTS_BEGIN
});

export const fetchProductsSuccess = products => ({
    type: FETCH_PRODUCTS_SUCCESS,
    payload: { products }
});

export const fetchProductsFailure = error => ({
    type: FETCH_PRODUCTS_FAILURE,
    payload: { error }
});

export const setChangePasswordFlag = value => ({
    type: SET_CHANGE_PASSWORD_FLAG,
    payload: { value }
});


export const setChangePasswordAPIFlag = value => ({
    type: SET_CHANGE_PASSWORD_API_FLAG,
    payload: { value }
});

export const setUserRoles = roles => ({
    type: SET_USER_ROLES,
    payload: {roles}
});

export const setWhiteLabelingResponse = response => ({
    type: SET_WHITELABELING_RESPONE,
    payload: {response}
});

export const  setBrandColor = brandColor => ({
    type: SET_BRAND_COLOR,
    payload: brandColor
});

export const  setBrandDarkColor = brandDarkColor => ({
    type: SET_BRAND_DARK_COLOR,
    payload: brandDarkColor
});
