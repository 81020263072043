import {SET_BRAND_COLOR, SET_BRAND_DARK_COLOR} from "./action";

const initialState = {
    brandColor: '#545cd8',
    brandDarkColor: '#2e38cb'
};
export default function urlReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case SET_BRAND_COLOR:
            return {
                ...state,
                brandColor: action.payload
            };
        case SET_BRAND_DARK_COLOR:
            return {
                ...state,
                brandDarkColor: action.payload
            };
        default:
            return state;
    }
}

