import {Redirect, Route, Switch} from 'react-router-dom';
import React, {Fragment, lazy, Suspense} from 'react';
import Loader from 'react-loaders';
import {LockScreen} from 'u-header';
import {ToastContainer} from 'react-toastify';
import PrivateRoute from '../../PrivateRoute';
import Cookies from 'universal-cookie';
import {AUTH_BASE_URL, BASE_COOKIE, USER_BASE_API} from '../../Constants';

const User = lazy(() => import('../../Pages'));
const EditProfile = lazy(() => import('../../Pages/ProfilePage/index'));
const ChangePassword = lazy(() => import('../../Pages/ChangePassword/index'));
const SwitchOrganization = lazy(() => import('../../Pages/SwitchOrganization/index'));
const NotFound = lazy(() => import('../../Pages/404'));

let defaultRedirect = '/';
let authenticated;
let lockScreen;

const LoadingMessage = () => (
    <div className="loader-container">
        <div className="loader-container-inner">
            <div className="text-center">
                <Loader type="ball-pulse"/>
            </div>
        </div>
    </div>
);

const AppMain = ({userObj}) => {

    const cookies = new Cookies();

    if (!cookies.get('lock')) {
        cookies.set('lock', false, {httpOnly: false, domain: BASE_COOKIE});
    } else {
        lockScreen = cookies.get('lock');
    }

    if (cookies.get('access_token')) {

        lockScreen = cookies.get('lock').toString() === 'true';
        defaultRedirect = '/user/organizationUser';
        authenticated = true;

    } else {
        window.location.href = AUTH_BASE_URL;
        authenticated = false;
    }

    const lock = () => {

        let url = window.location.href;
        cookies.set('lock', true, {httpOnly: false, domain: BASE_COOKIE});
        window.location.href = AUTH_BASE_URL + '#/pages/lock-screen?url=' + url;
    };

    const logout = () => {
        cookies.remove('access_token', {httpOnly: false, domain: BASE_COOKIE});
        cookies.remove('refresh_token', {httpOnly: false, domain: BASE_COOKIE});
        cookies.remove('lock', {httpOnly: false, domain: BASE_COOKIE});
        window.location.href = AUTH_BASE_URL + "/#/pages/login";
    };
    return (
        <Fragment>
            <LockScreen userObj={userObj} baseCookie={BASE_COOKIE} authUrl={AUTH_BASE_URL} userBaseAPI={USER_BASE_API}/>

            {/* Components */}
            <Suspense fallback={<LoadingMessage/>}>
                <Switch>

                    <Route
                        path="/user"
                        component={User}
                    />

                    <PrivateRoute
                        authenticated={authenticated}
                        path="/profile"
                        lock={lockScreen}
                        component={EditProfile}
                    />

                    <PrivateRoute
                        authenticated={authenticated}
                        path="/changepassword"
                        lock={lockScreen}
                        component={ChangePassword}
                    />

                    <PrivateRoute
                        authenticated={authenticated}
                        path="/switchorganization"
                        lock={lockScreen}
                        component={SwitchOrganization}
                    />

                    <Route path="/404">
                        <NotFound/>
                    </Route>


                    <Route path="**" render={() => (
                        <Redirect to="/404"/>
                    )}/>

                </Switch>
            </Suspense>


            <Route exact path="/" render={() => <Redirect to={defaultRedirect}/>}/>
            <ToastContainer/>
        </Fragment>
    );
};

export default AppMain;
