const SERVER_ENV = window && window.location && window.location.hostname;

let ENV_APP_BASE;
let ENV_API_BASE;
let ENV_API_BASE_URL;
let ENV_USER_BASE;
let ENV_USER_BASE_URL;
let ENV_BASE_COOKIE;
let ENV_ROLLBAR_ACCESS_TOKEN;
let ENV_AUDIT_BASE;
let SERVER_ENVIRONMENT;
let TOTP_TWO_FACT = false;
let GENERIC_TWO_FACT = false;
// Replace IF conditions as per the base URL

if (SERVER_ENV.includes('localhost')) {

  ENV_APP_BASE = 'http://localhost:3000';
  ENV_API_BASE = 'http://localhost:8076/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE = 'http://localhost:8076/api/usermodule';
  ENV_USER_BASE_URL = 'http://localhost:3001';
  ENV_AUDIT_BASE = 'https://audit.testing.kpininja.com';

  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = false;
  ENV_BASE_COOKIE = 'localhost';
  ENV_ROLLBAR_ACCESS_TOKEN = "";
  SERVER_ENVIRONMENT = "localhost";

} else if (SERVER_ENV.includes('-v4.testing.kpininja.com')) {
  ENV_APP_BASE = 'https://universe-auth-v4.testing.kpininja.com';
  ENV_API_BASE = 'https://universe-proxy-v4.testing.kpininja.com/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE = 'https://universe-proxy-v4.testing.kpininja.com/api/usermodule';
  ENV_USER_BASE_URL = 'https://universe-user-v4.testing.kpininja.com';
  ENV_AUDIT_BASE = 'https://audit.testing.kpininja.com';
  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = false;
  ENV_BASE_COOKIE = '.testing.kpininja.com';
  ENV_ROLLBAR_ACCESS_TOKEN = "b88107f336944c1687be4dd5560b1578";
  SERVER_ENVIRONMENT = "testing";
} else if (SERVER_ENV.includes('testing.kpininja.com')) {
  ENV_APP_BASE = 'https://universe.testing.kpininja.com';
  ENV_API_BASE = 'https://universegateway.api.testing.kpininja.com/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE = 'https://universegateway.api.testing.kpininja.com/api/usermodule';
  ENV_USER_BASE_URL = 'https://user.testing.kpininja.com';
  ENV_AUDIT_BASE = 'https://audit.testing.kpininja.com';
  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = true;
  ENV_BASE_COOKIE = '.testing.kpininja.com';
  ENV_ROLLBAR_ACCESS_TOKEN = "b88107f336944c1687be4dd5560b1578";
  SERVER_ENVIRONMENT = "testing";
} else if (SERVER_ENV.includes('staging.kpininja.com')) {
  ENV_APP_BASE = 'https://universe.staging.kpininja.com';
  ENV_API_BASE = 'https://universegateway.api.staging.kpininja.com/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE = 'https://universegateway.api.staging.kpininja.com/api/usermodule';
  ENV_USER_BASE_URL = 'https://user.staging.kpininja.com';
  ENV_AUDIT_BASE = 'https://audit.staging.kpininja.com';
  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = false;
  ENV_BASE_COOKIE = '.staging.kpininja.com';
  ENV_ROLLBAR_ACCESS_TOKEN = "e13fd4bea8ca475895257fc386cb7ecc";
  SERVER_ENVIRONMENT = "staging";
} else if (SERVER_ENV.includes('idahohde.kpininja.com')) {
  ENV_APP_BASE = 'https://universe.idahohde.kpininja.com';
  ENV_API_BASE = 'https://universegateway.api.idahohde.kpininja.com/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE = 'https://universegateway.api.idahohde.kpininja.com/api/usermodule';
  ENV_USER_BASE_URL = 'https://users.idahohde.kpininja.com';
  ENV_AUDIT_BASE = 'https://audit.idahohde.kpininja.com';
  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = true;
  ENV_BASE_COOKIE = '.idahohde.kpininja.com';
  ENV_ROLLBAR_ACCESS_TOKEN = "d357e39819a548078e97dfad1aeaa514";
  SERVER_ENVIRONMENT = "prodidaho";
} else if (SERVER_ENV.includes('preproduction.kpininja.com')) {
  ENV_APP_BASE = 'https://universe.preproduction.kpininja.com';
  ENV_API_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE = 'https://universegateway.api.preproduction.kpininja.com/api/usermodule';
  ENV_USER_BASE_URL = 'https://user.preproduction.kpininja.com';
  ENV_AUDIT_BASE = 'https://audit.preproduction.kpininja.com';
  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = true;
  ENV_BASE_COOKIE = '.preproduction.kpininja.com';
  ENV_ROLLBAR_ACCESS_TOKEN = "d357e39819a548078e97dfad1aeaa514";
  SERVER_ENVIRONMENT = "preproduction";
} else if (SERVER_ENV.includes('.kpininja.com')) {
  ENV_APP_BASE = 'https://universe.kpininja.com';
  ENV_API_BASE = 'https://universegateway.api.kpininja.com/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE = 'https://universegateway.api.kpininja.com/api/usermodule';
  ENV_USER_BASE_URL = 'https://users.kpininja.com';
  ENV_AUDIT_BASE = 'https://audit.kpininja.com';
  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = true;
  ENV_BASE_COOKIE = '.kpininja.com';
  ENV_ROLLBAR_ACCESS_TOKEN = "d357e39819a548078e97dfad1aeaa514";
  SERVER_ENVIRONMENT = "production";
} else if (SERVER_ENV.includes('preproduction.nehii.org')) {
  ENV_APP_BASE = 'https://ninjauniverse.preproduction.nehii.org';
  ENV_API_BASE = 'https://universegateway.api.preproduction.nehii.org/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE = 'https://universegateway.api.preproduction.nehii.org/api/usermodule';
  ENV_USER_BASE_URL = 'https://user.preproduction.nehii.org';
  ENV_AUDIT_BASE = 'https://audit.preproduction.nehii.org';
  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = true;
  ENV_BASE_COOKIE = '.preproduction.nehii.org';
  ENV_ROLLBAR_ACCESS_TOKEN = "ee5e835b19a745c582b6024bad40c2be";
  SERVER_ENVIRONMENT = "preprod-nehii";
} else if (SERVER_ENV.includes('nehii.org')) {
  ENV_APP_BASE = 'https://ninjauniverse.nehii.org';
  ENV_API_BASE = 'https://universegateway.api.nehii.org/api/authmodule';
  ENV_API_BASE_URL = ENV_API_BASE + '';
  ENV_USER_BASE = 'https://universegateway.api.nehii.org/api/usermodule';
  ENV_USER_BASE_URL = 'https://user.nehii.org';
  ENV_AUDIT_BASE = 'https://audit.nehii.org';
  TOTP_TWO_FACT = false;
  GENERIC_TWO_FACT = true;
  ENV_BASE_COOKIE = '.nehii.org';
  ENV_ROLLBAR_ACCESS_TOKEN = "ee5e835b19a745c582b6024bad40c2be";
  SERVER_ENVIRONMENT = "prod-nehii";
}

export const AUTH_BASE_URL = ENV_APP_BASE;
export const API_BASE_URL = ENV_API_BASE_URL;
export const USER_BASE_API = ENV_USER_BASE + '/users';
export const BASE_COOKIE = ENV_BASE_COOKIE;
export const USER_BASE_URL = ENV_USER_BASE_URL;
export const ROLLBAR_ACCESS_TOKEN = ENV_ROLLBAR_ACCESS_TOKEN;
export const ENVIRONMENT = SERVER_ENVIRONMENT;
export const ENV = {
  code_version: '1.1.1',
  environment: 'user',
  RootModule: 7
};
export const ENV_AUDIT_BASE_API = ENV_AUDIT_BASE;
export const ACCESS_TOKEN = 'accessToken';
export const WHITELABELING = '/whiteLabeling';

//USER PROJECT
export const GETUSERROLES = '/getUserModules';
export const USERDETAIL = '/userdetail';
export const CHECKBANNERSTATUS = '/checkBannerStatus';
export const GETPILLARSBYFY = '/getPillarsByFY';
export const GETHEADERSBYFY = '/getHeadersByFY';
export const USERDETAILSMART = '/userdetailSmart';
export const USER_BACKEND_NAME = 'USERS';

export const ENABLE_TOTP_TWO_FACT = TOTP_TWO_FACT;
export const ENABLE_GENERIC_TWO_FACT = GENERIC_TWO_FACT;
