import {
    GETUSERROLES,
    USER_BASE_API,
    BASE_COOKIE,
    USERDETAIL,
    CHECKBANNERSTATUS,
    GETPILLARSBYFY,
    GETHEADERSBYFY,
    USERDETAILSMART,
    WHITELABELING
} from '../Constants';
import Cookies from 'universal-cookie';
import {AUTH_BASE_URL} from '../Constants';
import {handle403Page} from 'u-header';
const axios = require('axios');
const ACCEPT_TERMS_AND_CONDITIONS = "/acceptTermsAndConditions";


const cookies = new Cookies();

export const request = (options) => {

    cookies.set("isBannerStarted", false, {httpOnly: false, domain: BASE_COOKIE});

    const config = {
        headers: {'Content-Type': 'application/json'},
        url: options['url'],
        method: options['method'],
        data: options['body']
    };

    if (cookies.get('access_token', {httpOnly: false})) {
        config['headers']['Authorization'] = 'Bearer ' + cookies.get('access_token', {httpOnly: false});
    }

    if (navigator.onLine) {

        return axios.request(config)
            .then(response => {
                if (response.config.headers && response.headers.authorization) {
                    cookies.set("access_token", response.headers && response.headers.authorization, {
                        httpOnly: false,
                        domain: BASE_COOKIE
                    });
                }
                return response.data;
            })
            .catch((error) => {
                let response;
                if (error && error.response) {
                    if (error && error.response && error.response.status === 401) {
                        cookies.remove('access_token', {httpOnly: false, domain: BASE_COOKIE});
                        cookies.remove('refresh_token', {httpOnly: false, domain: BASE_COOKIE});
                        cookies.remove('lock', {httpOnly: false, domain: BASE_COOKIE});
                        window.location.href = AUTH_BASE_URL + '/#/pages/login'
                    } else if (error && error.response && error.response.status === 403) {
                        handle403Page(AUTH_BASE_URL);
                    } else {
                        response = {
                            status: false,
                            tempMassage: "Something went wrong"
                        };
                        return response;
                    }
                } else {
                    response = {
                        status: false,
                        tempMassage: "Something went wrong"
                    };
                    return response;
                }
            })
    } else {

        let response;
        response = {
            status: false,
            tempMassage: "INTERNET DISCONNECTED"
        };
        return response;
    }
};

export const request2 = (options) => {

    cookies.set("isBannerStarted", false, {httpOnly: false, domain: BASE_COOKIE});


    const config = {
        headers: {},
        url: options['url'],
        method: options['method'],
        data: options['body']
    };
    //const cookies = new Cookies();
    if (cookies.get('access_token', {httpOnly: false, domain: BASE_COOKIE})) {
        config['headers']['Authorization'] = 'Bearer ' + cookies.get('access_token', {
            httpOnly: false,
            domain: BASE_COOKIE
        });
    }


    if (navigator.onLine) {
        return axios.request(config).then(
            response => {
                if (parseInt(response.status, 10) === 200) {
                    return response.data;
                } else {
                    return Promise.reject(response);
                }
            }
        ).catch(error => {
            let response;
            if (error && error.response) {
                if (error.response.status === 401) {
                    cookies.remove('access_token', {httpOnly: false, domain: BASE_COOKIE});
                    cookies.remove('refresh_token', {httpOnly: false, domain: BASE_COOKIE});
                    cookies.remove('lock', {httpOnly: false, domain: BASE_COOKIE});
                    window.location.href = AUTH_BASE_URL + '/#/pages/login'
                } else if (error.response.status === 403) {
                    window.location.href = AUTH_BASE_URL + '/#/accessDenied'
                } else {
                    response = {
                        status: false,
                        tempMassage: "Something went wrong"
                    };
                    return response;
                }
            } else {
                response = {
                    status: false,
                    tempMassage: "Something went wrong"
                };
                return response;
            }
        });
    } else {

        let response;
        response = {
            status: false,
            tempMassage: "INTERNET DISCONNECTED"
        };
        return response;
    }
};

export async function getUserRoles() {

    return await request({

        url: USER_BASE_API + GETUSERROLES,
        method: 'get',
    });
}

export async function checkBannerStatusAPI() {

    return await request({

        url: USER_BASE_API + CHECKBANNERSTATUS + '?rootModuleId=7',
        method: 'get',
    });
}

export async function getPillarsByFyAPI() {

    return await request({

        url: USER_BASE_API + GETPILLARSBYFY,
        method: 'get',
    });
}

export async function getHeadersByFyAPI() {

    return await request({

        url: USER_BASE_API + GETHEADERSBYFY,
        method: 'get',
    });
}

export async function userDetailsAPI(type) {

    if (type === 'SMART') {
        return await request({
            url: USER_BASE_API + USERDETAILSMART,
            method: 'get',
        });
    } else if (type === 'user') {
        return await request({

            url: USER_BASE_API + USERDETAIL,
            method: 'get',
        });
    }
}

export async function getUserDetailsAPI(type) {

    let responseData = {};
    return await userDetailsAPI(type).then(userDetailResponse => {
        if (userDetailResponse && userDetailResponse.status) {

            responseData['root_company_module'] = userDetailResponse.data.modules;
            responseData['switch_company_option'] = userDetailResponse.profile['switchCompanyOption'];
            responseData['name'] = userDetailResponse.profile.name;
            responseData['client_name'] = userDetailResponse.profile['clientName'];
            responseData['logo'] = userDetailResponse.profile.logo;
            responseData['profile_photo'] = userDetailResponse.profile.profilePhoto;
            responseData['logo_'] = userDetailResponse.profile['logo1'];
            responseData['profile_photo_'] = userDetailResponse.profile['profilephoto1'];
            responseData['termsAndConditionFlag'] = userDetailResponse.termsAndConditionFlag;
            responseData['lockscreenTime'] = 15;
            responseData['logoutTime'] = 30;
            responseData['logo_size'] = userDetailResponse.horizontalLogo;
            responseData['isReportAvailable'] = userDetailResponse.isReportAvailable;
            responseData['rootModuleId'] = userDetailResponse.rootModuleId;
            responseData['chanagePasswordStatus'] = userDetailResponse.chanagePasswordStatus;
            //Can't get from APIs
            responseData['changePasswordUrl'] = '';
            responseData['switchOrganizationUrl'] = '';
            responseData['editProfileUrl'] = '';
            responseData['universeDashboard'] = '';
            responseData['reportsByModuleUrl'] = '';
            responseData['annualFinancialYearFlag'] = userDetailResponse.annualFinancialYearFlag;
        }

    }).then(() => whitelabelingAPI())
        .then(response => {

            if (response && response.status) {

                let whitelabelingResponse = response.data;
                if (whitelabelingResponse['rootWhiteLabeling'].logoUrl) {

                    responseData['logoUrl'] = whitelabelingResponse['rootWhiteLabeling'].logoUrl;
                } else {

                    responseData['logoClass'] = 'left_logo_initial';
                }
                responseData['logoText'] = whitelabelingResponse['rootWhiteLabeling'].logoText;
                responseData['logoSizes'] = whitelabelingResponse['rootWhiteLabeling'].horizontalLogo;
                responseData['logoClick'] = whitelabelingResponse['rootWhiteLabeling']['clickeableLogo'];
                responseData['logoClickLink'] = whitelabelingResponse['rootWhiteLabeling'].logoClickLink;
                responseData['text'] = whitelabelingResponse['rootWhiteLabeling']['footerTextBox'];
                responseData['footerText'] = whitelabelingResponse['rootWhiteLabeling'].footerText;
                responseData['footerLink'] = whitelabelingResponse['rootWhiteLabeling'].footerLink;
                responseData['termsAndConditionsTitle'] =
                    whitelabelingResponse['rootWhiteLabeling'].termsAndConditionsTitle;
                responseData['privacyPolicyTitle'] =
                    whitelabelingResponse['rootWhiteLabeling'].privacyPolicyTitle;
                responseData['termsAndConditionsDescription'] =
                    whitelabelingResponse['rootWhiteLabeling'].termsAndConditionsDescription;
                responseData['privacyPolicyTitleDescription'] =
                    whitelabelingResponse['rootWhiteLabeling']['termsAndConditionsTitleText'];
                responseData['privacyPolicyText'] =
                    whitelabelingResponse['rootWhiteLabeling']['privacyPolicyTitleText'];
            }
        })
        .then(() => checkBannerStatusAPI())
        .then(checkBannerStatusResponse => {

            if (checkBannerStatusResponse && checkBannerStatusResponse.status) {

                responseData['responseData'] = checkBannerStatusResponse['bannerStatus'];
            } else {

                responseData['responseData'] = false;
            }
        })
        .then(() => getPillarsByFyAPI())
        .then(pillarsByFyResponse => {

            if (pillarsByFyResponse.pillarLabel) {

                responseData['pillarLabel'] = pillarsByFyResponse.pillarLabel;
            } else {

                responseData['pillarLabel'] = 'pillars';
            }
        })
        .then(() => getHeadersByFyAPI())
        .then(headersByFyResponse => {

            if (headersByFyResponse['headerLabel']) {

                responseData['trailCategoryLabel'] = headersByFyResponse['headerLabel'];
            } else {

                responseData['trailCategoryLabel'] = 'Trail Category';
            }
            if (headersByFyResponse.verificationMethod) {

                responseData['verificationMethod'] = headersByFyResponse.verificationMethod;
            } else {

                responseData['verificationMethod'] = 'Validation Type';
            }
            return responseData;
        });
}

export async function whitelabelingAPI() {

    return await request({

        url: USER_BASE_API + WHITELABELING,
        method: 'GET'
    })
}

export async function acceptTermsAndConditionsAPI() {

    return await request({

        url: USER_BASE_API + ACCEPT_TERMS_AND_CONDITIONS,
        method: 'GET'
    })
}
