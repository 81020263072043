import React from 'react';
import {connect} from 'react-redux';
import { Route, withRouter, Redirect } from "react-router-dom";
import {AUTH_BASE_URL, USER_BACKEND_NAME, USER_BASE_URL} from '../Constants';
import {setChangePasswordAPIFlag, setPasswordFlag} from "../reducers/action";

let authBaseUrl = AUTH_BASE_URL+'#/pages/lock-screen?url='+window.location.href;
const PrivateRoute = ({ component: Component, authenticated, lock, moduleRole, changePasswordFlag, changePasswordAPIFlag, userObj,roles,  dispatch, ...rest}) => {

  if(!changePasswordAPIFlag){

      dispatch(setChangePasswordAPIFlag(true));
      if (userObj && userObj.status) {

        if (userObj['chanagePasswordStatus']) {
          dispatch(setPasswordFlag(userObj['chanagePasswordStatus']));
          window.location.href = USER_BASE_URL + '/#/changepassword';
        }
      }
  }else{
    if(changePasswordFlag){
      window.location.href = USER_BASE_URL + '/#/changepassword';
    }
  }
  return <Route
    {...rest}
    render={props =>
      authenticated ?
        lock.toString() === 'true' ? (
            window.location.href = authBaseUrl
          ) :
          (moduleRole)
            ?
            (roles.indexOf(moduleRole) !== -1)
              ?
              (<Component  {...rest} {...props} />)
              :
              window.location.href = AUTH_BASE_URL + '/#/dashboards?accessDenied='+moduleRole
            : (<Component {...rest} {...props} />)
        :
        (
          <Redirect
            to={{
              pathname: '/pages/login',
              state: {from: props.location}
            }}
          />
        )
    }
  />
};

const mapStateToProp = state => ({
  changePasswordFlag: state.PasswordReducer.changePasswordFlag,
  changePasswordAPIFlag: state.PasswordReducer.changePasswordAPIFlag,
  roles: state.PasswordReducer.items,
  userObj: state.PasswordReducer.userdetailObject
});


export default withRouter(connect(mapStateToProp)(PrivateRoute));
